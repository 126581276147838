import { graphql } from 'gatsby'
import get from 'lodash/get'
import React, { Component } from 'react'
import AdSense from 'react-adsense'

import { withStyles } from '@material-ui/styles'

import Layout from '../components/layout'
import Categories from '../components/molecules/categories'
import ShareButtons from '../components/molecules/share'
import BlogList from '../components/organisms/blogList'
import BlogCategory from '../models/blogCategory/blogCategory'
import { AllBlogPost } from '../models/blogPost/blogPost'
import { ArticleGoogleStructuredConfig } from '../models/seoConfig/ArticleGoogleStructure'
import SEOConfig from '../models/seoConfig/seoConfig'
import PageType from '../models/siteConfig/pageType'
import SiteConfig from '../models/siteConfig/siteConfig'

const styles = {
  title: {
    textAlign: 'center',
    margin: '20px 0'
  }
}

class IndexPage extends Component {
  render() {
    const classes = get(this, 'props.classes')
    const blogCategories: BlogCategory[] = get(this, 'props.data.allContentfulBlogPostCategory.nodes')
    const blogPosts: AllBlogPost[] = get(this, 'props.data.allContentfulBlogPost.edges')
    const siteConfig: SiteConfig = get(this, 'props.data.site.siteMetadata.siteConfig')
    const seoConfig: SEOConfig = new SEOConfig(PageType.TOP, null, null, null, siteConfig, siteConfig.baseURL + '/' + siteConfig.siteImageSrc, null)
    const articleStructured: ArticleGoogleStructuredConfig = {}
    return (
      <Layout seoConfig={seoConfig} blogCategories={blogCategories} articleStructured={articleStructured}>
        <marquee scrollamount="10">ガジェット紹介・ゲーム実況の記事を掲載しているサイトとなります</marquee>
        <h2 className={classes.title}>最新記事</h2>
        <BlogList blogPosts={blogPosts}></BlogList>
        <div>
          <AdSense.Google
            style={{ display: 'block' }}
            client='ca-pub-5524131539322102'
            slot='4430167272'
            format='auto'
            responsive='true'
          />
        </div>
        <h2 className={classes.title}>記事のカテゴリ</h2>
        <Categories categories={blogCategories} />
        <ShareButtons url={seoConfig.ogpURL()}></ShareButtons>
      </Layout>
    )
  }
}

export default withStyles(styles)(IndexPage)

export const blogPostListQuery = graphql`
query BlogPostListQuery {
  site {
    siteMetadata {
      siteConfig {
        title
        description
        siteImageSrc
        baseURL
        author {
          name
          nameCall
          bio
          medias {
            name
            link
            viewBox
            paths {
              d
              fill
            }
          }
        }
      }
    }
  }
  allContentfulBlogPostCategory {
    totalCount
    nodes {
      name
      slug
    }
  }
  allContentfulBlogPost(limit:5, sort: {fields: [createdAt], order: DESC}) {
    edges {
      node {
        title
        slug
        description{
          description
        }
        categories {
          name
          slug
        }
        heroImage{
          file{
            url
          }
          fixed(width:1200,height:630){
            ...GatsbyContentfulFixed_withWebp
          }
          fluid(maxWidth:720, quality:30){
            ...GatsbyContentfulFluid_withWebp
          }
        }
        content {
          childMarkdownRemark {
            excerpt(pruneLength: 99999)
            html
          }
        }
        affiliates {
          amazonLink
          amazonImage {
            amazonImage
          }
          rakutenLink {
            rakutenLink
          }
          name
        }
        createdAt(formatString: "記事投稿日 YYYY年 MM月 DD日")
        updatedAt(formatString: "最終更新日 YYYY年 MM月 DD日")
        vlog 
      }
    }
  }
}
`
